export default {
  data() {
    return {
      inputItems: {
        experiences: [],
      },
    };
  },
  computed: {
    someExperienceSelected() {
      return this.inputItems.experiences.length > 0;
    },
  },
  mounted() {},
  methods: {
    toggleInput(e) {
      $(e.target).parent().toggleClass('p-entry-p-hero__content__panel__checkbox__label active');
    },
    doEntry(path) {
      window.location = `${path}&experiences=${this.inputItems.experiences}`;
    },
  },
};
